import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const Footer = () => {
    return (
        <footer className="sticky bottom-0">
            <Grid
                sx={(theme) => ({
                    backgroundColor: theme.palette.primary.main,
                    height: theme.spacing(11),
                    [theme.breakpoints.down('md')]: {
                        alignItems: 'start'
                    }
                })}
                spacing={1}
                container
                alignItems="center"
            >
                <Grid container item xs={12} justifyContent="center">
                    <Grid container item justifyContent="center" md="auto" xs={12}>
                        <Typography
                            textAlign="center"
                            component="span"
                            sx={(theme) => ({
                                color: theme.palette.primary.contrastText,
                                fontWeight: 700,
                                marginRight: theme.spacing(1)
                            })}
                        >
                            Dirección:
                        </Typography>
                        <Typography
                            textAlign="center"
                            component="span"
                            sx={(theme) => ({
                                color: theme.palette.primary.contrastText,
                                marginRight: theme.spacing(1)
                            })}
                        >
                            Virrey Liniers 871 (CABA)
                        </Typography>
                    </Grid>

                    <Grid item container justifyContent="center" md="auto" xs={12}>
                        <Typography
                            textAlign="center"
                            component="span"
                            sx={(theme) => ({
                                color: theme.palette.primary.contrastText,
                                fontWeight: 700,
                                marginRight: theme.spacing(1)
                            })}
                        >
                            Teléfono:
                        </Typography>
                        <Typography
                            textAlign="center"
                            component="span"
                            sx={(theme) => ({
                                color: theme.palette.primary.contrastText,
                            })}
                        >
                            011 4932-6422
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        textAlign="center"
                        sx={(theme) => ({
                            color: theme.palette.primary.contrastText,
                        })}
                    >
                        Maez© {new Date().getFullYear()}
                    </Typography>
                </Grid>

            </Grid>
        </footer>
    );
};

export default Footer;
