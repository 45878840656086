import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ContactForm from "../../components/ContactForm";

const ContactPage = () => {
    return (
        <Grid container justifyContent="center" flexDirection="column" spacing={5}>

            <Grid sx={{ backgroundColor: '#ececec'}} item xs="auto">
                <Typography
                    sx={(theme) => ({
                        color: theme.palette.text.secondary,
                    })}
                    textAlign="center"
                    variant="h3"
                >
                    ¿Querés hacernos una consulta?
                </Typography>
            </Grid>

            <Grid sx={{ backgroundColor: '#ececec'}} item xs="auto" container justifyContent="center">
                <ContactForm />
            </Grid>
        </Grid>
    );
};

export default ContactPage;
