import React, { useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";

import { navItems } from "../../router";
import SidebarMenu from "../SidebarMenu";

const AppBar = () => {
    const navigate = useNavigate();
    const [ anchorEl, setAnchorEl ] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <MuiAppBar position="fixed" component="nav">
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <Link className="main-link" to="/">Maez - Cosas Útiles</Link>
                    </Typography>
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                        {navItems.map((item) => (
                            <Button key={item.name} onClick={() => navigate(item.link)} sx={{ color: "#fff", textTransform: "capitalize" }}>
                                {item.name}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ display: { xs: 'block', sm: 'none' }}}>
                        <SidebarMenu anchorEl={anchorEl} handleMenu={handleMenu} handleClose={handleClose} />
                    </Box>
                </Toolbar>
            </MuiAppBar>
        </Box>
    );
};

export default AppBar;
