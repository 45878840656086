import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

import pintureria from "../../assets/images/pintureria.webp";
import pintureria917 from "../../assets/images/pintureria917.webp";
import pintureria1130 from "../../assets/images/pintureria1130.webp";
import pintureria1500 from "../../assets/images/pintureria1500.webp";
import pintureria2048 from "../../assets/images/pintureria2048.webp";

import griferia from "../../assets/images/griferia.webp";
import griferia1120 from "../../assets/images/griferia1120.webp";
import griferia1500 from "../../assets/images/griferia1500.webp";
import griferia1790 from "../../assets/images/griferia1790.webp";
import griferia2048 from "../../assets/images/griferia2048.webp";

import adhesivos256 from "../../assets/images/adhesivos256.webp";
import adhesivos from "../../assets/images/adhesivos.webp";
import adhesivos1120 from "../../assets/images/adhesivos1120.webp";
import adhesivos1500 from "../../assets/images/adhesivos1500.webp";
import adhesivos1790 from "../../assets/images/adhesivos1790.webp";
import adhesivos2048 from "../../assets/images/adhesivos2048.webp";

import lubricantes from "../../assets/images/lubricantes.webp";
import lubricantes1120 from "../../assets/images/lubricantes1120.webp";
import lubricantes1500 from "../../assets/images/lubricantes1500.webp";
import lubricantes1790 from "../../assets/images/lubricantes1790.webp";
import lubricantes2048 from "../../assets/images/lubricantes2048.webp";

import serrucho from "../../assets/images/serrucho.webp";
import serrucho840 from "../../assets/images/serrucho840.webp";
import serrucho1120 from "../../assets/images/serrucho1120.webp";
import serrucho1410 from "../../assets/images/serrucho1410.webp";
import serrucho1650 from "../../assets/images/serrucho1650.webp";
import serrucho1860 from "../../assets/images/serrucho1860.webp";
import serrucho2048 from "../../assets/images/serrucho2048.webp";

import herramientas256 from "../../assets/images/herramientas256.webp";
import herramientas from "../../assets/images/herramientas.webp";
import herramientas1450 from "../../assets/images/herramientas1450.webp";
import herramientas1770 from "../../assets/images/herramientas1770.webp";
import herramientas2048 from "../../assets/images/herramientas2048.webp";

import cajaHerramientas from "../../assets/images/caja-herramientas.webp";
import cajaHerramientas870 from "../../assets/images/caja-herramientas870.webp";
import cajaHerramientas1120 from "../../assets/images/caja-herramientas1120.webp";
import cajaHerramientas1410 from "../../assets/images/caja-herramientas1410.webp";
import cajaHerramientas1650 from "../../assets/images/caja-herramientas1650.webp";
import cajaHerramientas1860 from "../../assets/images/caja-herramientas1860.webp";
import cajaHerramientas2048 from "../../assets/images/caja-herramientas2048.webp";

import pulverizador256 from "../../assets/images/pulverizador256.webp";
import pulverizador from "../../assets/images/pulverizador.webp";
import pulverizador1120 from "../../assets/images/pulverizador1120.webp";
import pulverizador1410 from "../../assets/images/pulverizador1410.webp";
import pulverizador1650 from "../../assets/images/pulverizador1650.webp";
import pulverizador1860 from "../../assets/images/pulverizador1860.webp";
import pulverizador2048 from "../../assets/images/pulverizador2048.webp";

import disco256 from "../../assets/images/disco256.webp";
import disco from "../../assets/images/disco.webp";
import disco940 from "../../assets/images/disco940.webp";
import disco1120 from "../../assets/images/disco1120.webp";
import disco1360 from "../../assets/images/disco1360.webp";
import disco1560 from "../../assets/images/disco1560.webp";
import disco1740 from "../../assets/images/disco1740.webp";
import disco1900 from "../../assets/images/disco1900.webp";
import disco2048 from "../../assets/images/disco2048.webp";

const itemData = [
    {
        img: pintureria,
        title: "Pinturería",
        link: 'productos?categoria=Pinturería',
        srcSet: `
            ${pintureria} 612w,
            ${pintureria917} 917w,
            ${pintureria1130} 1130w,
            ${pintureria1500} 1500w,
            ${pintureria2048} 2048w
        `
    },
    {
        img: griferia,
        title: "Grifería",
        link: 'productos?categoria=Grifería',
        srcSet: `
            ${griferia} 600w,
            ${griferia1120} 1120w,
            ${griferia1500} 1500w,
            ${griferia1790} 1790w,
            ${griferia2048} 2048w
        `
    },
    {
        img: adhesivos,
        title: "Adhesivos y colas",
        link: 'productos?categoria=Adhesivos+y+Colas',
        srcSet: `
            ${adhesivos256} 255w,
            ${adhesivos} 680w,
            ${adhesivos1120} 1120w,
            ${adhesivos1500} 1500w,
            ${adhesivos1790} 1790w,
            ${adhesivos2048} 2048w
        `
    },
    {
        img: lubricantes,
        title: "Lubricantes",
        link: 'productos?categoria=Lubricantes',
        srcSet: `
            ${lubricantes} 575w,
            ${lubricantes1120} 1120w,
            ${lubricantes1500} 1500w,
            ${lubricantes1790} 1790w,
            ${lubricantes2048} 2048w
        `
    },
    {
        img: serrucho,
        title: "Serruchos y arcos",
        link: 'productos?categoria=Serruchos+y+Arcos',
        srcSet: `
            ${serrucho} 400w,
            ${serrucho840} 840w,
            ${serrucho1120} 1120w,
            ${serrucho1410} 1410w,
            ${serrucho1650} 1650w,
            ${serrucho1860} 1860w,
            ${serrucho2048} 2048w
        `
    },
    {
        img: herramientas,
        title: "Herramientas",
        link: 'productos?categoria=Herramientas+Manuales',
        srcSet: `
            ${herramientas256} 256w,
            ${herramientas} 1024w,
            ${herramientas1450} 1450w,
            ${herramientas1770} 1770w,
            ${herramientas2048} 2048w,
        `
    },
    {
        img: cajaHerramientas,
        title: "Cajas de herramientas",
        link: 'productos?categoria=Cajas+de+Herramientas',
        srcSet: `
            ${cajaHerramientas} 500w,
            ${cajaHerramientas870} 870w,
            ${cajaHerramientas1120} 1120w,
            ${cajaHerramientas1410} 1410w,
            ${cajaHerramientas1650} 1650w,
            ${cajaHerramientas1860} 1860w,
            ${cajaHerramientas2048} 2048w
        `
    },
    {
        img: pulverizador,
        title: "Pulverizadores",
        link: 'productos?categoria=Pulverizadores',
        srcSet: `
            ${pulverizador256} 256w,
            ${pulverizador} 800w,
            ${pulverizador1120} 1120w,
            ${pulverizador1410} 1410w,
            ${pulverizador1650} 1650w,
            ${pulverizador1860} 1860w,
            ${pulverizador2048} 2048w
        `
    },
    {
        img: disco,
        title: "Discos",
        link: 'productos?categoria=Discos',
        srcSet: `
            ${disco256} 256w,
            ${disco} 717w,
            ${disco940} 940w,
            ${disco1120} 1120w,
            ${disco1360} 1360w,
            ${disco1560} 1560w,
            ${disco1740} 1740w,
            ${disco1900} 1900w,
            ${disco2048} 2048w
        `
    },
];

const ProductsCategories = () => {
    return (
        <Box>
            <ImageList sx={{ margin: 0 }} variant="masonry">
                {itemData.map((item) => (
                    <Link key={item.title} to={item.link}>
                        <ImageListItem key={item.title}>
                            <img src={item.img} srcSet={item.srcSet} sizes="(min-width: 1800px) calc(36.08vw + 45px), calc(38.65vw + 7px)" alt={item.title} />
                            <ImageListItemBar
                                style={{
                                    whiteSpace: "break-spaces !important",
                                }}
                                classes={{ title: "white-space: break-spaces" }}
                                title={item.title}
                            />
                        </ImageListItem>
                    </Link>
                ))}
            </ImageList>
        </Box>
    );
};

export default ProductsCategories;
