import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    createTheme,
    ThemeProvider,
    responsiveFontSizes,
} from "@mui/material/styles";

import { RouterProvider } from 'react-router-dom';
import router from "./router";

import "./index.css";

const theme = responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                main: "#cf7e34",
            },
            tertiary: {
                main: '#808080'
            }
        },
    })
);

const App = () => {
    return (
        <>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_WEB_SITE_KEY}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <RouterProvider router={router} />
                    <ToastContainer limit={1} />
                </ThemeProvider>
            </GoogleReCaptchaProvider>
        </>
    );
};

export default App;
