import React from "react";
import Grid from "@mui/material/Grid";
import { useSearchParams } from "react-router-dom";

import ProductListItem from "../ProductListItem";
import products from "../../assets/products/products";

const ProductList = () => {
    const [ searchParams ] = useSearchParams();

    return (
        <Grid height="95%" container item spacing={2}>
            {
                products
                .filter(product => searchParams.get('categoria') ? product.categories.includes(searchParams.get('categoria')) : true)
                .map(product => 
                    <Grid
                        sx={{ height: 'fit-content'}}
                        container
                        justifyContent="center"
                        item
                        key={product.imagePath}
                        xs={12} sm={6} md={4} xl={3}
                    >
                        <ProductListItem
                            product={product}
                        />
                </Grid>)
            }
        </Grid>
    );
};

export default ProductList;
