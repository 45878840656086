import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";

import evel from "../../assets/logos/evel.webp";
import fijacionesPy from "../../assets/logos/fijaciones-py.webp";
import forjaQuintana from "../../assets/logos/forja-quintana.webp";
import fortex from "../../assets/logos/fortex.webp";
import gherardi from "../../assets/logos/gherardi.webp";
import neike from "../../assets/logos/neike.webp";
import norton from "../../assets/logos/norton.webp";
import tresPuntos from "../../assets/logos/tres-puntos.webp";

const logos = [
    {
        img: evel,
        name: "Evel",
    },
    {
        img: fijacionesPy,
        name: "Fijaciones Py",
    },
    {
        img: forjaQuintana,
        name: "Forja Quintana",
    },
    {
        img: fortex,
        name: "Fortex",
    },
    {
        img: gherardi,
        name: "Gherardi",
    },
    {
        img: neike,
        name: "Neike",
    },
    {
        img: norton,
        name: "Norton",
    },
    {
        img: tresPuntos,
        name: "Tres Puntos",
    },
];

const BrandSwiper = () => {
    const theme = useTheme();
    const largeSize = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Swiper
            slidesPerView={largeSize ? 2 : 4}
            spaceBetween={30}
            autoplay
            allowTouchMove={false}
            modules={[Autoplay]}
        >
            {logos.map((logo) => (
                <SwiperSlide key={logo.name}>
                    <img
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            maxHeight: "250px",
                        }}
                        src={logo.img}
                        alt={logo.name}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default BrandSwiper;
