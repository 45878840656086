import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import PropTypes from "prop-types";
import { useSearchParams } from 'react-router-dom';

const getStyles = (category, searchParams, theme) => ({
    fontWeight:
        searchParams.get('categoria') === category
            ? theme.typography.fontWeightMedium
            : theme.typography.fontWeightRegular
});

const ProductCategorySelector = (props) => {
    const theme = useTheme();
    const [searchParams] = useSearchParams();

    const styles = {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: 'initial'
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "initial",
            color: 'initial'
        },
        "&.Mui-focused": {
            color: 'initial'
        },
    };

    return (
        <FormControl error={false} sx={{ width: '100%' }}>
            <InputLabel sx={styles} error={false} id="categories">Categoría</InputLabel>
            <Select
                labelId="categories"
                error={false}
                value={searchParams.get('categoria') || ''}
                onChange={(e) => props.handleSelection(e.target.value)}
                input={<OutlinedInput style={styles} sx={styles} error={false} label="Categoría" />}
                renderValue={(selectedCategory) => (
                    <Box sx={{ gap: 0.5 }}>
                        <Chip key={selectedCategory} label={selectedCategory} />
                    </Box>
                )}
            >
                {props.categories.map((category) => (
                    <MenuItem
                        key={category}
                        value={category}
                        style={getStyles(category, searchParams, theme)}
                    >
                        {category}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

ProductCategorySelector.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleSelection: PropTypes.func.isRequired
};

export default ProductCategorySelector;
