import React from "react";
import { useTheme } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from '@mui/material/ListItemText';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSearchParams, createSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

import ProductList from "../../components/ProductList";
import categories from "../../assets/categories/categories";
import ProductCategorySelector from "../../components/ProductCategorySelector";
import useScrollToTop from "../../hooks/useScrollToTop";

const ProductsPage = () => {
    const theme = useTheme();
    useScrollToTop();
    const mediumSize = useMediaQuery(theme.breakpoints.down('md'));
    const [searchParams, setSearchParams] = useSearchParams();

    const handleSearchParams = (category) => {
        if (searchParams.get('categoria') === category) {
            searchParams.delete('categoria');
            setSearchParams(searchParams);
        }
        else {
            setSearchParams(createSearchParams([[ 'categoria', category]]));
        }
    };

    return (
        <Grid container sx={(theme) => ({ 
            minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`
        })}>
            <Grid item xs={2} sx={{ display: mediumSize ? 'none' : 'block' }}>
                <List>
                    {categories.map(category => (
                    <ListItem key={category} disablePadding>
                        <ListItemButton selected={category === searchParams.get('categoria')} onClick={() => handleSearchParams(category)}>
                        <ListItemText primary={category} />
                        </ListItemButton>
                    </ListItem>
                    ))}
                </List>
            </Grid>

            <Grid container sx={(theme) => ({ display: mediumSize ? 'block': 'none', paddingX: theme.spacing(2)})} spacing={2}>
                <Grid item xs={12} sx={{ display: mediumSize ? 'block': 'none' }}>
                    <ProductCategorySelector
                        categories={categories}
                        handleSelection={handleSearchParams}
                    />
                </Grid>
            </Grid>

            <Grid item container flexDirection="column" xs>
                <Grid sx={(theme) => ({padding: theme.spacing(2)})} container item xs>
                    <ProductList />
                </Grid>

                <Grid sx={(theme) => ({ marginY: theme.spacing(3)})} display="flex" justifyContent="center" alignItems="center" xs item>
                    <Typography
                        sx={(theme) => ({
                            color: theme.palette.text.secondary,
                        })}
                        variant="h5"
                        textAlign="center"
                    >
                        ¿Seguís sin encontrar lo que buscas? Tenemos muchos productos más, {<Link to="../contacto">contactate con nosotros</Link>} 
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ProductsPage;
