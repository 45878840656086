import React, { useState, useCallback } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

import ProductsCategories from "../../components/ProductsCategories";
import BrandSwiper from "../../components/BrandSwiper";
import maez from "../../assets/logos/maez.webp";
import maez300 from "../../assets/logos/maez300.webp";
import maez1409 from "../../assets/logos/maez1409.webp";
import maez2048 from "../../assets/logos/maez2048.webp";

const marker = { lat: -34.62087648492949, lng: -58.413919210176495 };

const zoom = 18;

const center = {
    lat: -34.62087648492949,
    lng: -58.413919210176495,
};

const HomePage = () => {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyC0Lytb6QPHW9NNMwfJva6U4meM9tnvtlk",
    });

    const setMap = useState(null)[1];

    const onLoad = useCallback((map) => {
        new window.google.maps.Marker({
            position: marker,
            map,
            title: "Maez - Cosas Útiles",
        });

        setMap(map);
    }, [setMap]);

    const onUnmount = useCallback((map) => {
        setMap(null);
    }, [setMap]);

    return (
      <>
          <Grid sx={(theme) => ({ paddingY: theme.spacing(2) })} item xs={12}>
              <img
                style={{ height: '300px', width: "100%" }}
                src={maez}
                srcSet={`
                  ${maez300} 298w,
                  ${maez1409} 1409w,
                  ${maez2048} 2048w
                `}
                sizes="100vw"
                alt="maez_logo"
              />
          </Grid>

          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            sx={(theme) => ({ marginBottom: theme.spacing(3) })}
          >
              <Typography
                sx={(theme) => ({
                    color: theme.palette.text.secondary,
                })}
                variant="h4"
              >
                Nuestro catálogo
              </Typography>
          </Grid>

          <CSSTransition in timeout={300} appear classNames="products">
            <Grid
              item
              xs={10}
              sm={9}
              sx={(theme) => ({ marginBottom: theme.spacing(3) })}
            >
              <ProductsCategories />
            </Grid>
          </CSSTransition>

          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            sx={(theme) => ({ marginBottom: theme.spacing(10) })}
          >
              <Typography
                sx={(theme) => ({
                    color: theme.palette.text.secondary,
                })}
                variant="h4"
                textAlign="center"
              >
                Podés consultar todos nuestros productos {<Link to="productos">acá</Link>} 
              </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            sx={(theme) => ({ marginBottom: theme.spacing(3) })}
          >
            <Typography
              sx={(theme) => ({
                  color: theme.palette.text.secondary,
              })}
              variant="h5"
            >
              Trabajamos con las siguientes marcas
            </Typography>
          </Grid>

          <Grid item xs={10} marginBottom={3}>
              <BrandSwiper />
          </Grid>

        <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            sx={(theme) => ({ marginBottom: theme.spacing(3) })}
        >
            <Typography
                sx={(theme) => ({
                    color: theme.palette.text.secondary,
                })}
                variant="h4"
            >
                Dónde encontrarnos
            </Typography>
        </Grid>

        <Grid item xs={12} marginBottom={4}>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={{
                        display: "flex",
                        height: "400px",
                        width: "100%",
                    }}
                    center={center}
                    zoom={zoom}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                />
            ) : null}
        </Grid>
      </>
    );
};

export default HomePage;
