const categories = [
    "Pinturería",
    "Lijas",
    "Espátulas",
    "Grifería",
    "Adhesivos y Colas",
    "Herramientas Manuales",
    "Cintas métricas",
    "Lubricantes",
    "Serruchos y Arcos",
    "Discos",
    "Construcción",
    "Pulverizadores",
    "Cajas de Herramientas",
    "Varios"
];

export default categories;
