import React from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import PropTypes from "prop-types";

const ProductListItem = (props) => {
    return (
        <Card>
            <CardHeader
                title={props.product.name}
                subheader={props.product.categories.join(', ')}
            />
            <CardMedia
                sx={{ maxHeight: '300px', maxWidth: '300px', width: 'auto', height: 'auto', margin: 'auto' }}
                component="img"
                image={props.product.imagePath}
                alt={props.product.name}
            />
      </Card>
    );
};

ProductListItem.propTypes = {
    product: PropTypes.shape({
        name: PropTypes.string,
        imagePath: PropTypes.string,
        categories: PropTypes.arrayOf(PropTypes.string)
    }).isRequired
};

export default ProductListItem;
