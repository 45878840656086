import React from "react";
import Grid from "@mui/material/Grid";
import { Outlet } from "react-router-dom";

import AppBar from "../../components/AppBar";
import Footer from "../../components/Footer";

const DefaultLayout = () => {
    return (
        <>
            <AppBar />
            <Grid
                component="main"
                container
                justifyContent="center"
                sx={(theme) => ({
                    marginTop: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(1)})`,
                    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(11)})`
                })}
            >
                <Outlet />
            </Grid>
            <Footer />
        </>
    );
};

export default DefaultLayout;
