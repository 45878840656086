import { createBrowserRouter } from "react-router-dom";
import ProductsPage from './pages/Products';
import HomePage from "./pages/Home";
import DefaultLayout from "./layouts/DefaultLayout";
import ContactPage from "./pages/Contact";

export const navItems = [
    {
        name: 'Productos',
        link: '/productos'
    },
    {
        name: 'Contacto',
        link: '/contacto'
    }
];

export default createBrowserRouter([
    {
        id: 'root',
        path: '/',
        element: <DefaultLayout />,
        children: [
            {
                index: true,
                element: <HomePage />
            },
            {
                path: 'productos',
                element: <ProductsPage />
            },
            {
                path: 'contacto',
                element: <ContactPage />                
            }
        ]
    }
]);