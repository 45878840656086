import React, { useCallback, useState } from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

const initialValues = {
    name: '',
    email: '',
    phone: '',
    message: ''
};

const emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validationSchema = Yup.object({
    name: Yup.string()
        .max(50, 'El nombre no puede superar los 50 caracteres')
        .required('Este campo es obligatorio'),
    email: Yup.string()
        .matches(emailRegex, 'Ingrese una dirección de correo válida')
        .max(60, 'La dirección de correo no puede superar los 60 caracteres')
        .required('Este campo es obligatorio'),
    phone: Yup.string()
        .matches(/^[0-9]+$/, 'El número de teléfono no puede contener letras, paréntesis, espacios o caracteres especiales')
        .min(8, 'El número de teléfono debe contener al menos 8 cifras')
        .max(12, 'El número de teléfono no puede superar las 12 cifras'),
    message: Yup.string()
        .max(350, 'El mensaje no puede superar los 350 caracteres')
        .required('Este campo es obligatorio')
});

const ContactForm = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [ loading, setLoading ] = useState(false);
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

    const onSubmit = useCallback(async (values, formik) => {
        if (!executeRecaptcha) {
            console.error('Execute recaptcha not yet available');
            return;
        }

        setLoading(true);
        
        try {
            const captchaToken = await executeRecaptcha('authenticate');
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/contact`, { ...values, captchaToken });
            formik.resetForm();
            toast.success("Formulario enviado, a la brevedad nos pondremos en contacto", {
                position: toast.POSITION.TOP_RIGHT,
                className: isMobileView ? 'toast-mobile' : null,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                pauseOnFocusLoss: false,
                theme: "colored",
            });
        }
        catch(e) {
            console.error(e);
            toast.error("Ups! Ocurrió un error por favor intentalo de nuevo más tarde", {
                position: toast.POSITION.TOP_RIGHT,
                className: isMobileView ? 'toast-mobile' : null,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                pauseOnFocusLoss: false,
                theme: "colored",
            });
        }
        finally {
            setLoading(false);
        }
    }, [executeRecaptcha, isMobileView]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    });

    return (
        <Grid item container xs={11} md={8} lg={6}>
            <form style={{ width: '100%' }} noValidate onSubmit={formik.handleSubmit}>
                <Grid  container flexDirection="column" alignItems="center" spacing={3}>
                    <Grid container item xs>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                required
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                error={!!formik.errors.name && formik.touched.name}
                                helperText={formik.touched.name ? formik.errors.name : null}
                                label="Nombre"
                                autoComplete="true"
                            />
                        </FormControl>
                    </Grid>

                    <Grid container item xs>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                required
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                error={!!formik.errors.email && formik.touched.email}
                                helperText={formik.touched.email ? formik.errors.email : null}
                                label="E-mail"
                                autoComplete="true"
                            />
                        </FormControl>
                    </Grid>


                    <Grid container item xs>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                name="phone"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                                error={!!formik.errors.phone && formik.touched.phone}
                                helperText={formik.touched.phone ? formik.errors.phone : null}
                                label="Teléfono"
                                autoComplete="true"
                            />
                        </FormControl>
                    </Grid>

                    <Grid container item xs>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                required
                                name="message"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.message}
                                error={!!formik.errors.message && formik.touched.message}
                                helperText={formik.touched.message ? formik.errors.message : null}
                                multiline
                                rows={6}
                                label="Consulta"
                                autoCorrect="false"
                                autoComplete="false"
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs>
                        <Typography marginTop={-2} color="grey" variant="caption" textAlign="center" component="p">
                            This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                        </Typography>
                    </Grid>

                    <Grid container item xs={12} sm={3}>
                        <Button
                            sx={{ textTransform: 'none' }}
                            disabled={loading}
                            startIcon={loading ? <CircularProgress size={20} /> : null}
                            fullWidth
                            variant="contained"
                            type="submit"
                        >
                            {loading ? 'Enviando...' : 'Enviar'}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
};

export default ContactForm;
