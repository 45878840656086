import React from "react";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { navItems } from "../../router";

const SidebarMenu = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={props.handleMenu}
                color="inherit"
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(props.anchorEl)}
                onClose={props.handleClose}
            >
                {navItems.map(item => 
                    <MenuItem
                        onClick={() =>{
                            navigate(item.link);
                            props.handleClose();
                        }}
                    >
                        {item.name}
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

SidebarMenu.propTypes = {
    anchorEl: PropTypes.object.isRequired,
    handleMenu: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired
};

export default SidebarMenu;
