import rodillos from "../images/image3.webp";
import rodillosConPinceles from "../images/image5.webp";
import rodillosPoliester from "../images/image6.webp";
import bandeja from "../images/image12.webp";
import bandejaConAccesorios from "../images/image8.webp";
import pinceletas from "../images/image14.webp";
import pinceles from "../images/image127.webp";
import precintos from "../images/image35.webp";
import lijaT277 from "../images/D_NQ_NP_713064-MLA40229166101_122019-O.webp";
import lijaA257 from "../images/D_NQ_NP_670907-MLA47542654765_092021-O.webp";
import lijaA219 from "../images/79680_0.webp";
import lijaA319 from "../images/63660_0.webp";
import lijaT417 from "../images/D_NQ_NP_941701-MLA30673933906_052019-O.webp";
import espatulasPlastico from "../images/image18.webp";
import espatulaMaderaAncha from "../images/image31.webp";
import espatulaMaderaAngosta from "../images/image32.webp";
import duchaPvc from "../images/image23.webp";
import duchaCromada from "../images/image24.webp";
import cintaTeflon from "../images/image99.webp";
import grifoMesada from "../images/image25.webp";
import grifoPared from "../images/image89.webp";
import mezcladoraCurva from "../images/image27.webp";
import duchadorConSoporte from "../images/image28.webp";
import terrajaPvc from "../images/image39.webp";
import flexiblePvc from "../images/image115.webp"
import flexibleAcero from "../images/image116.webp";
import adhesivoContacto from "../images/image36.webp";
import fratacho from "../images/image43.webp";
import escuadraAlbanil from "../images/image45.webp";
import plomada from "../images/image46.webp";
import maza from "../images/image47.webp";
import martillo from "../images/image49.webp";
import cuchara from "../images/image56.webp";
import llana from "../images/image54.webp";
import pasador from "../images/image58.webp";
import aceiteLiquido from "../images/image52.webp";
import aceiteEnAerosol from "../images/image51.webp";
import grasaGrafito from "../images/image114.webp";
import grasa from "../images/image113.webp";
import soldador from "../images/image60.webp";
import cutter from "../images/image74.webp";
import nivelMadera from "../images/image66.webp";
import nivelAluminio from "../images/image110.webp";
import pinzaElectrodos from "../images/image62.webp";
import punta from "../images/image64.webp";
import cortaFierros from "../images/image65.webp";
import barreta from "../images/image111.webp";
import lapizAlbanil from "../images/image63.webp";
import candado from "../images/image75.webp";
import alicate from "../images/image81.webp";
import pinza from "../images/image79.webp";
import picoLoro from "../images/image78.webp";
import pelaCables from "../images/image80.webp";
import serrucho from "../images/image93.webp";
import arco from "../images/image77.webp";
import cepilloMadera from "../images/image95.webp";
import cepilloPvc from "../images/image67.webp";
import prensaG from "../images/image98.webp";
import trampera from "../images/image100.webp";
import roldana from "../images/image102.webp";
import salpicadora from "../images/image106.webp";
import rueda from "../images/image118.webp";
import soporteEstantes from "../images/image126.webp";
import bracket from "../images/image125.webp";
import pistolaAplicadora from "../images/image104.webp";
import remachadora from "../images/image105.webp";
import discoDiamantado from "../images/image107.webp";
import sierraCircular from "../images/image108.webp";
import discoCorteMadera from "../images/image109.webp";
import pala from "../images/image91.webp";
import balde from "../images/image122.webp";
import metro from "../images/image59.webp";
import clavera from "../images/image123.webp";
import escuadraCarpintero from "../images/image103.webp";
import pulverizador1l from "../images/image90.webp";
import pulverizador2l from "../images/image69.webp";
import pulverizador5l from "../images/image70.webp";
import pulverizador8l from "../images/image71.webp";
import pulverizador10l from "../images/image72.webp";
import agrimensor from "../images/image137.webp";
import cinta2m from "../images/image138.webp";
import cinta3m from "../images/image139.webp";
import cinta5m from "../images/image112.webp";
import cinta8m from "../images/image88.webp";
import cinta10m from "../images/image85.webp";
import cajaHerramientas from "../images/image132.webp";
import cajaHerramientas2 from "../images/image129.webp";
import cajaHerramientas3 from "../images/image130.webp";
import cajaHerramientas4 from "../images/image131.webp";
import cajaHerramientas5 from "../images/image133.webp";
import cajaHerramientas6 from "../images/image134.webp";
import cajaHerramientas7 from "../images/image135.webp";
import cajaHerramientas8 from "../images/image136.webp";

const products = [
    {
        imagePath: rodillos,
        name: 'Rodillos',
        categories: ['Pinturería']
    },
    {
        imagePath: rodillosConPinceles,
        name: 'Rodillos con pinceles',
        categories: ['Pinturería']
    },
    {
        imagePath: rodillosPoliester,
        name: 'Rodillos de poliester y simil lana',
        categories: ['Pinturería']
    },
    {
        imagePath: bandeja,
        name: 'Bandejas de pintor',
        categories: ['Pinturería']
    },
    {
        imagePath: bandejaConAccesorios,
        name: 'Bandeja de pintor con accesorios',
        categories: ['Pinturería']
    },
    {
        imagePath: pinceletas,
        name: 'Pinceletas',
        categories: ['Pinturería']
    },
    {
        imagePath: pinceles,
        name: 'Pinceles',
        categories: ['Pinturería']
    },
    {
        imagePath: precintos,
        name: 'Precintos',
        categories: ['Pinturería']
    },
    {
        imagePath: lijaT277,
        name: 'Lija al agua T277 NorClass - Óxido de aluminio',
        categories: ["Lijas"]
    },
    {
        imagePath: lijaA257,
        name: 'Lija massa/madera A257 - Óxido de aluminio',
        categories: ["Lijas"]
    },
    {
        imagePath: lijaA219,
        name: 'Lija antiempastante A219 no-fil - Óxido de aluminio',
        categories: ["Lijas"]
    },
    {
        imagePath: lijaA319,
        name: 'Lija antiempastante A319 no-fil - Óxido de aluminio',
        categories: ["Lijas"]
    },
    {
        imagePath: lijaT417,
        name: 'Lija al agua T417 - Carburo de silicio',
        categories: ["Lijas"]
    },
    {
        imagePath: espatulasPlastico,
        name: 'Juego de espátulas de plástico',
        categories: ["Espátulas"]
    },
    {
        imagePath: espatulaMaderaAncha,
        name: 'Espátula cabo de madera ancha',
        categories: ["Espátulas"]
    },
    {
        imagePath: espatulaMaderaAngosta,
        name: 'Espátula cabo de madera angosta',
        categories: ["Espátulas"]
    },
    {
        imagePath: duchaPvc,
        name: 'Duchas móvil PVC',
        categories: ['Grifería']
    },
    {
        imagePath: duchaCromada,
        name: 'Ducha móvil cromada',
        categories: ['Grifería']
    },
    {
        imagePath: cintaTeflon,
        name: 'Cinta de teflón',
        categories: ['Grifería']
    },
    {
        imagePath: grifoMesada,
        name: 'Grifo de mesada',
        categories: ['Grifería']
    },
    {
        imagePath: grifoPared,
        name: 'Grido de pared',
        categories: ['Grifería']
    },
    {
        imagePath: mezcladoraCurva,
        name: 'Mezcladora curva',
        categories: ['Grifería']
    },
    {
        imagePath: duchadorConSoporte,
        name: 'Duchador con soporte',
        categories: ['Grifería']
    },
    {
        imagePath: terrajaPvc,
        name: 'Terraja de PVC',
        categories: ['Grifería']
    },
    {
        imagePath: flexiblePvc,
        name: 'Flexible de PVC',
        categories: ['Grifería']
    },
    {
        imagePath: flexibleAcero,
        name: 'Flexible de acero',
        categories: ['Grifería']
    },
    {
        imagePath: adhesivoContacto,
        name: 'Adhesivo de contacto',
        categories: ['Adhesivos y Colas']
    },
    {
        imagePath: fratacho,
        name: 'Fratacho',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: escuadraAlbanil,
        name: 'Escuadra de albañil',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: plomada,
        name: 'Plomada',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: maza,
        name: 'Maza',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: martillo,
        name: 'Martillo',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: cuchara,
        name: 'Cuchara',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: llana,
        name: 'Llana',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: pasador,
        name: 'Pasador',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: aceiteLiquido,
        name: 'Aceite líquido',
        categories: ['Lubricantes']
    },
    {
        imagePath: aceiteEnAerosol,
        name: 'Aceite en aerosol',
        categories: ['Lubricantes']
    },
    {
        imagePath: grasaGrafito,
        name: 'Grasa con grafito',
        categories: ['Lubricantes']
    },
    {
        imagePath: grasa,
        name: 'Grasa',
        categories: ['Lubricantes']
    },
    {
        imagePath: soldador,
        name: 'Soladador',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: cutter,
        name: 'Cutter',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: nivelMadera,
        name: 'Nivel de madera',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: nivelAluminio,
        name: 'Nivel de aluminio',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: pinzaElectrodos,
        name: 'Pinza porta electrodos',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: punta,
        name: 'Punta',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: cortaFierros,
        name: 'Corta fierros',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: barreta,
        name: 'Barreta',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: lapizAlbanil,
        name: 'Lápiz de alabñil',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: candado,
        name: 'Candado',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: alicate,
        name: 'Alicate',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: pinza,
        name: 'Pinzas',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: picoLoro,
        name: 'Pico de loro',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: pelaCables,
        name: 'Pela cables',
        categories: ['Herramientas Manuales']
    },
    {
        imagePath: serrucho,
        name: 'Serrucho',
        categories: ['Serruchos y Arcos']
    },
    {
        imagePath: arco,
        name: 'Arco',
        categories: ['Serruchos y Arcos']
    },
    {
        imagePath: cepilloMadera,
        name: 'Cepillo de madera',
        categories: ['Serruchos y Arcos']
    },
    {
        imagePath: cepilloPvc,
        name: 'Cepillo PVC',
        categories: ['Serruchos y Arcos']
    },
    {
        imagePath: prensaG,
        name: 'Prensa G',
        categories: ['Varios']
    },
    {
        imagePath: trampera,
        name: 'Trampera',
        categories: ['Varios']
    },
    {
        imagePath: roldana,
        name: 'Roldana',
        categories: ['Varios']
    },
    {
        imagePath: salpicadora,
        name: 'Salpicadora',
        categories: ['Varios']
    },
    {
        imagePath: rueda,
        name: 'Rueda',
        categories: ['Varios']
    },
    {
        imagePath: soporteEstantes,
        name: 'Soporte de estantes',
        categories: ['Varios']
    },
    {
        imagePath: bracket,
        name: 'Bracket',
        categories: ['Varios']
    },
    {
        imagePath: pistolaAplicadora,
        name: 'Pistola aplicadora',
        categories: ['Varios']
    },
    {
        imagePath: remachadora,
        name: 'Remachadora',
        categories: ['Varios']
    },
    {
        imagePath: discoDiamantado,
        name: 'Disco diamantado',
        categories: ['Discos']
    },
    {
        imagePath: sierraCircular,
        name: 'Sierra circular',
        categories: ['Discos']
    },
    {
        imagePath: discoCorteMadera,
        name: 'Disco de corte madera',
        categories: ['Discos']
    },
    {
        imagePath: pala,
        name: 'Pala',
        categories: ['Construcción']
    },
    {
        imagePath: balde,
        name: 'Balde',
        categories: ['Construcción']
    },
    {
        imagePath: metro,
        name: 'Metro',
        categories: ['Construcción']
    },
    {
        imagePath: clavera,
        name: 'Clavera',
        categories: ['Construcción']
    },
    {
        imagePath: escuadraCarpintero,
        name: 'Escuadra de carpintero',
        categories: ['Construcción']
    },
    {
        imagePath: pulverizador1l,
        name: 'Pulverizador 1L',
        categories: ['Pulverizadores']
    },
    {
        imagePath: pulverizador2l,
        name: 'Pulverizador 2L',
        categories: ['Pulverizadores']
    },
    {
        imagePath: pulverizador5l,
        name: 'Pulverizador 5L',
        categories: ['Pulverizadores']
    },
    {
        imagePath: pulverizador8l,
        name: 'Pulverizador 8L',
        categories: ['Pulverizadores']
    },
    {
        imagePath: pulverizador10l,
        name: 'Pulverizador 10L',
        categories: ['Pulverizadores']
    },
    {
        imagePath: agrimensor,
        name: 'Agrimensor',
        categories: ['Cintas métricas']
    },
    {
        imagePath: cinta2m,
        name: 'Cinta métrica 2m',
        categories: ['Cintas métricas']
    },
    {
        imagePath: cinta3m,
        name: 'Cinta métrica 3m',
        categories: ['Cintas métricas']
    },
    {
        imagePath: cinta5m,
        name: 'Cinta métrica 5m',
        categories: ['Cintas métricas']
    },
    {
        imagePath: cinta8m,
        name: 'Cinta métrica 8m',
        categories: ['Cintas métricas']
    },
    {
        imagePath: cinta10m,
        name: 'Cinta métrica 10m',
        categories: ['Cintas métricas']
    },
    {
        imagePath: cajaHerramientas,
        name: 'Caja de herramientas',
        categories: ['Cajas de Herramientas']
    },
    {
        imagePath: cajaHerramientas2,
        name: 'Caja de herramientas',
        categories: ['Cajas de Herramientas']
    },
    {
        imagePath: cajaHerramientas3,
        name: 'Caja de herramientas',
        categories: ['Cajas de Herramientas']
    },
    {
        imagePath: cajaHerramientas4,
        name: 'Caja de herramientas',
        categories: ['Cajas de Herramientas']
    },
    {
        imagePath: cajaHerramientas5,
        name: 'Caja de herramientas',
        categories: ['Cajas de Herramientas']
    },
    {
        imagePath: cajaHerramientas6,
        name: 'Caja de herramientas',
        categories: ['Cajas de Herramientas']
    },
    {
        imagePath: cajaHerramientas7,
        name: 'Caja de herramientas',
        categories: ['Cajas de Herramientas']
    },
    {
        imagePath: cajaHerramientas8,
        name: 'Caja de herramientas',
        categories: ['Cajas de Herramientas']
    }
];

export default products;
